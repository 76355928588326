<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="employees"
      class="elevation-1"
      item-key="id"
    >
    <template v-slot:[`item.actions`]="{item}">
      <v-icon @click="openEmployeeModal(item)">mdi-pencil</v-icon>
      <v-icon @click="openPromptModal(item)">mdi-delete</v-icon>
    </template>
    </v-data-table>
    <v-row no-gutters justify="center">
            <div>
              <v-btn @click="openEmployeeModal(defaultEmployee)" color="primary">
                <h1>+</h1>
              </v-btn>
            </div>
          </v-row>
       <employee-modal
        v-if="employeeModal"
        :employeeModal="employeeModal"
        :employee="employee"
        @insert-employee="insertEmployee"
        @update-employee="updateEmployee"
        @close-employee-modal="closeEmployeeModal">
       </employee-modal>    
       <prompt-modal
      v-if="promptModal"
      @confirm="deleteEmployee"
      @cancel="closePromptModal"
      :promptModal="promptModal"
      promptTitle="Verwijder werknemer"
      promptText="Weet u zeker dat u deze werknemer wilt verwijderen?"
    >
    <table>
  <tr>
    <th>Personeelnr:</th>
    <td>{{employee.employeeNumber}}</td>
  </tr>
  <tr>
    <th>Roepnaam:</th>
    <td>{{employee.firstName}}</td>
  </tr>
</table></prompt-modal>
  </div>
</template>

<script>
    import employeeModal from "../components/employeeModal"
import promptModal from '@/components/promptModal.vue'
import { errorHandler, fetchGET, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "employees",
  data() {
    return {
        employeeModal: false,
        promptModal: false,
        defaultEmployee:{
                firstName: "",
                initial: "",
                lastName: "",
                dateOfBirth: "",
                dateOfResignation: "",
                hourlyWage: 0,
                timetable:{
                  "Maandag": {start:"",eind:""},
                  "Dinsdag": {start:"",eind:""},
                  "Woensdag": {start:"",enid:""},
                  "Donderdag": {start:"",eind:""},
                  "Vrijdag": {start:"",eind:""},
                  "Zaterdag": {start:"",eind:""},
                  "Zondag": {start:"",eind:""},
                },
                uid: "",
                checkedIn: false
                },
        employee: {},
        employees: [],
      headers: [
      { text: "Voornaam", value: "firstName" },
      { text: "Voorletter", value: "initial" },
      { text: "Achternaam", value: "lastName" },
      { text: "Geboortedatum", value: "dateOfBirth" },
      { text: "Datum in dienst", value: "dateOfEmployment" },
      { text: "Datum uit dienst", value: "dateOfResignation" },
      { text: "Uurloon", value: "hourlyWage" },
      { text: "", value: "actions",align: "end",sortable:false },
     ]
    }
  },
  methods: {
      async insertEmployee(employee){
        try { 
          this.closeEmployeeModal()
          let response = await fetchPOST("insertEmployee",employee)          
          if(response.insertedCount===0) throw "insertEmployee"
          this.employees.push(response.ops[0])
          successHandler("Chauffeur opgeslagen")
        } catch (e) {
          errorHandler(e,"Chauffeur niet opgeslagen")
        }        
      },
      async updateEmployee(employee){
        try { 
          let response = await fetchPOST("updateEmployee",employee)          
          if(response.nModified===0) throw "updateEmployee"
          Object.assign(this.employee,employee)
          this.closeEmployeeModal()
          successHandler("Chauffeur opgeslagen")
        } catch (e) {
          errorHandler(e,"Chauffeur niet opgeslagen")
        }        
      },
    async deleteEmployee(){
        try { 
          let response = await fetchPOST("deleteEmployee",{_id:this.employee._id})
          if(response.deletedCount===0) throw "deleteEmployee"
          let index = this.employees.findIndex(employee=> employee._id ===this.employee._id)
          if(index>-1)this.employees.splice(index,1)
          this.closePromptModal()
          successHandler("Chauffeur verwijderd")
        } catch (e) {
          errorHandler(e,"Chauffeur niet verwijderd")
        }        
      },
      openEmployeeModal(employee){
        this.employee = employee
        this.employeeModal = true
      },
      closeEmployeeModal(){
        this.employee = {}
        this.employeeModal = false
      },
      openPromptModal(item) {
        this.employee = item
      this.promptModal = true
    },
    closePromptModal() {
      this.employee = {}
      this.promptModal = false
    },
  },
  computed: {

  },
  async mounted() {
    this.employees = await fetchGET("fetchEmployees",{})
  },
  components: {employeeModal,promptModal}
}
</script>

<style>
</style>