<template>
  <v-dialog :value="employeeModal" persistent hide-overlay width="500px">
    <v-card class="pa-2">
      <v-tabs v-model="tab">
        <v-tab key="data">Gegevens</v-tab>
        <v-tab key="timetable">Rooster</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
          <v-tab-item key="data">
<v-form v-model="formIsValid" ref="form">
        <v-text-field
          label="Voornaam"
          ref="firstName"
          v-model="thisEmployee.firstName"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Voorletter"
          ref="initial"
          v-model="thisEmployee.initial"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Achternaam"
          ref="lastName"
          v-model="thisEmployee.lastName"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Geboortedatum"
          ref="dateOfBirth"
          v-model="thisEmployee.dateOfBirth"
          :rules="[rules.required, rules.date]"
          hint="dd-mm-jjjj"
        ></v-text-field>
        <v-text-field
          label="Datum in dienst"
          ref="dateOfEmployment"
          v-model="thisEmployee.dateOfEmployment"
          :rules="[rules.required, rules.date]"
          hint="dd-mm-jjjj"
        ></v-text-field>
        <v-text-field
          label="Datum uit dienst"
          ref="dateOfResignation"
          v-model="thisEmployee.dateOfResignation"
          :rules="[rules.date]"
          hint="dd-mm-jjjj"
        ></v-text-field>
        <v-text-field
          label="Uurloon"
          ref="hourlyWage"
          type="number"
          v-model.number="thisEmployee.hourlyWage"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="UID"
          ref="uid"
          v-model="thisEmployee.uid"
          :rules="[rules.required]"
        ></v-text-field>
      </v-form>
          </v-tab-item>
          <v-tab-item key="timetable">
<ul>
    <li v-for="(day,prop) of thisEmployee.timetable" :key="prop">
        <ul>{{prop}}
            <li v-for="(time,prop2) of day" :key="prop2">
                <v-text-field :label="prop2" v-model="thisEmployee.timetable[prop][prop2]">

                </v-text-field>
            </li>
        </ul>
    </li>
</ul>
          </v-tab-item>
      </v-tabs-items>
      
      <v-btn-toggle class="d-flex justify-center">
        <v-btn color="primary" @click="closeDialog">Annuleer</v-btn>
        <v-btn color="primary" @click="insertEmployee">Opslaan</v-btn>
      </v-btn-toggle>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "employeeModal",
  props: { employeeModal: Boolean, employee: Object },
  data() {
    return {
      thisEmployee: JSON.parse(JSON.stringify(this.employee)),
      formIsValid: false,
      tab: null,
      rules: {
        required: value => !!value || "Veld verplicht.",
        date: value => {
          const pattern = /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4})$/
          return pattern.test(value) || !value || "Onjuiste datum."
        }
      }
    }
  },
  methods: {
    insertEmployee() {
      this.$refs.form.validate()
      if (this.formIsValid) {
        this.thisEmployee.fullName = this.thisEmployee.firstName +" "+ this.thisEmployee.lastName
        if (this.employee._id) this.$emit("update-employee", this.thisEmployee)
        else this.$emit("insert-employee", this.thisEmployee)
      }
    },
    closeDialog() {
      this.$emit("close-employee-modal")
    }
  },
  computed: {}
}
</script>

<style>
</style>